import { Trans, useTranslation } from "react-i18next";
import { FULL_MAP, STATUS_DONE, STATUS_ERROR, STATUS_IN_PROGRESS } from "../../../../common/constants/export-constants";
import { IconCsv } from "../../../../common/icons/IconCsv/IconCsv";
import { IconDelete } from "../../../../common/icons/IconDelete/IconDelete";
import { IconPdf } from "../../../../common/icons/IconPdf/IconPdf";
import { IconXls } from "../../../../common/icons/IconXls/IconXls";
import LeafDateTime from "../../../../common/leaf/LeafDateTime/LeafDateTime";
import LeafFilterButton from "../../../../common/leaf/LeafFilter/LeafFilterButton/LeafFilterButton";
import { unixEpochToISO } from "../../../../common/util/formatDate";
import { getLongPeriodFormat } from "../../../../common/util/period";
import { storedLocale } from "../../../impersonation/util";
import defaultContent from "../../../../content/serverSideExport";
import { useAppSelector } from "../../../../store";
import { getLoginAffAbo } from "../../../../reducers/model/boot";
import { getCountryNameFromCode } from "../../../../common/util/formatCountryName";
import "./ServerSideExportDownload.scss";

// TODO: to be imported from RTK qeury
type Export = {
  exportId: string;
  exportStatus: string;
  pdfLoading?: boolean;
  excelLoading?: boolean;
  csvLoading?: boolean;
  losCount: number;
  enrichedCount: number;
  progressPercent: number;
  period: string;
  expiresInSeconds: number;
  startTime: EpochTimeStamp;
  noDownline: boolean;
  reqAffAbo: string;
  exportType: string;
  filteredByKPIS: boolean;
  countryCode: string;
};

type Props = {
  title: string;
  subTitle: string;
  exportTypeContent: {
    fullMap: string;
    fullMapAllKPIs: string;
    currentView: string;
  };
  exports: Export[];
  exportAnotherButton?: { text: string; onClick: () => void };
  showExportAnotherButton?: boolean;
  downloadButtonOnClick: Function;
  show: boolean;
};

const ServerSideExportDownload = (props: Props) => {
  const { t } = useTranslation(["serverSideExport"]);
  const {
    title,
    subTitle,
    exportTypeContent,
    exports,
    exportAnotherButton,
    showExportAnotherButton,
    downloadButtonOnClick,
    show,
  } = props;

  const locale = storedLocale();
  const boot = useAppSelector(({ boot }) => boot);
  const showExports = exports.length > 0;

  const options = {
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    year: "numeric",
    month: "short",
  } as Intl.DateTimeFormatOptions;

  const showIcon = (status: string) => {
    return status !== STATUS_IN_PROGRESS && status !== STATUS_ERROR;
  };

  const getExportTypeTitle = (exp: Export) => {
    if (exp.exportType === FULL_MAP) {
      return exp.filteredByKPIS === true ? exportTypeContent.fullMap : exportTypeContent.fullMapAllKPIs;
    }
    return exportTypeContent.currentView;
  };

  const getDoneMessage = (expiresInSeconds: number) => {
    const hours = expiresInSeconds / 3600;
    const moreThan1Hrs = hours > 1;
    const left1Hr = hours === 1;

    const lessThanMsg =
      moreThan1Hrs || left1Hr
        ? ""
        : `${t(
            "availableExportModal.exportFile.lessThan",
            defaultContent["availableExportModal"]["exportFile"]["lessThan"],
          )}`;
    const remainingHrs = moreThan1Hrs ? Math.round(hours) : 1;
    const hourMsg = moreThan1Hrs
      ? `${t(
          "availableExportModal.exportFile.doneHoursMessage",
          defaultContent["availableExportModal"]["exportFile"]["doneHoursMessage"],
        )}`
      : `${t(
          "availableExportModal.exportFile.doneHourMessage",
          defaultContent["availableExportModal"]["exportFile"]["doneHourMessage"],
        )}`;

    return ` - ${t(
      "availableExportModal.exportFile.doneExpiresInMessage",
      defaultContent["availableExportModal"]["exportFile"]["doneExpiresInMessage"],
    )} ${lessThanMsg} ${remainingHrs} ${hourMsg} `;
  };

  const getExportStatusMessage = (exp: Export) => {
    switch (exp.exportStatus) {
      case STATUS_ERROR:
        return `${t(
          "availableExportModal.exportFile.exportError",
          defaultContent["availableExportModal"]["exportFile"]["exportError"],
        )}`;
      case STATUS_IN_PROGRESS:
        return `${t(
          "availableExportModal.exportFile.inProgressMessage",
          defaultContent["availableExportModal"]["exportFile"]["inProgressMessage"],
        )}`;
      case STATUS_DONE:
        return getDoneMessage(exp.expiresInSeconds);
    }
  };

  if (show === false) return <></>;

  return (
    <div className="server-side-export-download">
      <div className="server-side-export-download__header">
        <div className="server-side-export-download__header__title">{title}</div>
        {showExports === true && <div className="server-side-export-download__header__subtitle">{subTitle}</div>}
      </div>
      <div className="server-side-export-download__content">
        {showExports === false && (
          <div className="server-side-export-download__no-content">
            <Trans>
              {t(
                "availableExportModal.noExportsAvailable",
                defaultContent["availableExportModal"]["noExportsAvailable"],
              )}
            </Trans>
          </div>
        )}
        {exports.map((exp, index) => (
          <div className="server-side-export-download__export-detail" key={index}>
            <div className="server-side-export-download__export-type">{getExportTypeTitle(exp)}</div>
            <div className="server-side-export-download__divider-line"></div>
            <div className="server-side-export-download__export-period">
              <Trans>
                {" "}
                {t(
                  "availableExportModal.exportFile.period",
                  defaultContent["availableExportModal"]["exportFile"]["period"],
                )}
              </Trans>{" "}
              {getLongPeriodFormat(exp.period, locale)}
            </div>
            <div className="server-side-export-download__affAbo-country">
              <Trans>
                {t(
                  "availableExportModal.exportFile.aboDetail",
                  defaultContent["availableExportModal"]["exportFile"]["aboDetail"],
                  { aboNo: exp.reqAffAbo, country: getCountryNameFromCode(exp.countryCode, locale) },
                )}
              </Trans>
            </div>
            <div className="server-side-export-download__start-time">
              <Trans>
                {t(
                  "availableExportModal.exportFile.startTime",
                  defaultContent["availableExportModal"]["exportFile"]["startTime"],
                )}
              </Trans>{" "}
              <LeafDateTime date={unixEpochToISO(exp.startTime)} options={options} ignoreTimeZone={true} />
            </div>
            <div className="server-side-export-download__icons">
              <div
                className={`server-side-export-download__download-icons server-side-export-download__download-icons${
                  showIcon(exp.exportStatus) ? "--show" : ""
                }`}
              >
                <div
                  tabIndex={0}
                  role="button"
                  onClick={() => downloadButtonOnClick(exp.exportId, "excel")}
                  aria-label={`${t(
                    "availableExportModal.excelLabel",
                    defaultContent["availableExportModal"]["excelLabel"],
                  )}`}
                  className="server-side-export-download__icon"
                >
                  <IconXls loading={exp.excelLoading} />
                </div>
                <div
                  tabIndex={0}
                  role="button"
                  onClick={() => downloadButtonOnClick(exp.exportId, "csv")}
                  aria-label={`${t(
                    "availableExportModal.csvLabel",
                    defaultContent["availableExportModal"]["csvLabel"],
                  )}`}
                  className="server-side-export-download__icon"
                >
                  <IconCsv loading={exp.csvLoading} />
                </div>
                <div
                  tabIndex={0}
                  role="button"
                  onClick={() => downloadButtonOnClick(exp.exportId, "pdf")}
                  aria-label={`${t(
                    "availableExportModal.pdfLabel",
                    defaultContent["availableExportModal"]["pdfLabel"],
                  )}`}
                  className="server-side-export-download__icon"
                >
                  <IconPdf loading={exp.pdfLoading} />
                </div>
                <div className="server-side-export-download__icon-divider"></div>
              </div>
              <div
                tabIndex={0}
                role="button"
                aria-label={`${t(
                  "availableExportModal.deleteLabel",
                  defaultContent["availableExportModal"]["deleteLabel"],
                )}`}
                className="server-side-export-download__icon"
              >
                <IconDelete />
              </div>
            </div>
            <div className="server-side-export-download__export-status">
              <div
                className={`server-side-export-download__ready server-side-export-download__ready--${exp.exportStatus}`}
              >
                <Trans>
                  {t(
                    "availableExportModal.exportFile.doneBoldMessage",
                    defaultContent["availableExportModal"]["exportFile"]["doneBoldMessage"],
                  )}
                </Trans>
              </div>
              <div
                className={`server-side-export-download__status-message server-side-export-download__status-message--${exp.exportStatus}`}
              >
                {getExportStatusMessage(exp)}
              </div>
            </div>
          </div>
        ))}
      </div>
      {showExportAnotherButton === true && (
        <div className="server-side-export-download__footer">
          <LeafFilterButton
            text={exportAnotherButton?.text || ""}
            onButtonClick={() => exportAnotherButton?.onClick()}
            theme="dark"
          />
        </div>
      )}
    </div>
  );
};

export default ServerSideExportDownload;
