/**
 * Parse the given period String into JavaScript Date object
 * @param {string} period must be in YYYYMM format. eg. 201901
 */
export const parsePeriodToDate = (period: string) => {
  const month = getMonthFromPeriod(period);
  const year = getYearFromPeriod(period);
  const day = "01";
  // convert period text into isoDate string format (YYYY-MM-DDTHH:MM:SS.sssZ)
  const isoDate = `${year}-${appeadLeadingZeroToMonth(month)}-${day}T00:00:00.000Z`;
  return new Date(isoDate);
};

// Extract Month value from period. Period should be in YYYYMM format
export const getMonthFromPeriod = (period: string) => {
  return Number(period.toString().substring(4, 6));
};

// Extract Year value from period. Period should be in YYYYMM format
export const getYearFromPeriod = (period: string) => {
  return Number(period.toString().substring(0, 4));
};

// append leading 0 to month value when month is < 10
const appeadLeadingZeroToMonth = (month: number) => {
  return month < 10 ? `0${month}` : month;
};

// Returns ISO date for given epoch time
export const unixEpochToISO = (unixTime: number): string => {
  const date = new Date(unixTime * 1000);
  const isoDate = date.toISOString();
  return isoDate;
};

export const convertDateToYYYYMM = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-based

  return `${year}${month}`;
};

// Inserts hyphen between the period string, Example - Input:202402 , Output: 02-2024
export const getPeriodWithHyphen = (period: string) => {
  const periodWithHyphen = period.slice(-2) + "-" + period.slice(0, -2);
  return periodWithHyphen;
};
