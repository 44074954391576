export const STATUS_NOT_EXPORTED = "NOT_EXPORTED";
export const STATUS_PENDING = "PENDING";
export const STATUS_IN_PROGRESS = "IN_PROGRESS";
export const STATUS_DONE = "DONE";
export const STATUS_ERROR = "ERROR";
export const FULL_MAP = "FULL_MAP";
export const CURRENT_VIEW = "CURRENT_VIEW";

export const FORMAT_CSV = "csv";
export const FORMAT_XLSX = "xlsx";

export const COLOR_NEUTRAL = "#D9D9D9";
export const COLOR_POSITIVE = "#107F47";
export const COLOR_ERROR = "#D91734";

export const MODULE_CUSTOMER = "customer";
export const MODULE_ORDER = "order";
