import { Trans, useTranslation } from "react-i18next";
import { track } from "@amwaycommon/acc-mixins/analytics";
import LeafPanel from "../../../../common/leaf/LeafPanel/LeafPanel";
import defaultContent from "../../../../content/annualPerformanceDashboard";
import TEALIUM from "../../../../common/enums/tealium-enums";
import "./AnnualInfoModal.scss";
import { getCurrentPeriod, getPerformanceYear, getPerformanceYearShort } from "../../../../common/util/period";

type Props = {
  show: boolean;
  setShowInfoModal: Function;
};

export const AnnualInfoModal = ({ show, setShowInfoModal }: Props) => {
  const { t } = useTranslation(["annualPerformanceDashboard"]);

  if (show) {
    track(
      TEALIUM.U_TAG_METHOD.LINK,
      TEALIUM.EVENT_NAME.CLICK_ACTION,
      "annual_dashboard",
      TEALIUM.CATEGORY.MY_PERFORMANCE_DASH,
      "",
      "info_icon",
    );
  }

  return (
    <div className="AnnualInfoDrawer">
      <LeafPanel
        title={t("modalTitle", defaultContent["modalTitle"])}
        show={show}
        onCloseClick={() => setShowInfoModal(false)}
        id={"annual-performance-info-modal"}
      >
        <div className="AnnualInfoDrawer__wrapper">
          <section>
            <h2 className="AnnualInfoDrawer__title">
              <Trans>{t("spMonthsSubTitle", defaultContent["spMonthsSubTitle"])}</Trans>
            </h2>
            <p className="AnnualInfoDrawer__desc">
              <Trans>{t("spMonthsContent", defaultContent["spMonthsContent"])}</Trans>
            </p>
          </section>
          <section>
            <h2 className="AnnualInfoDrawer__title">
              <Trans>{t("pySubTitle", defaultContent["pySubTitle"])}</Trans>
            </h2>
            <p className="AnnualInfoDrawer__desc">
              <Trans>
                {t("pyContent", defaultContent["pyContent"], {
                  shortPY: getPerformanceYearShort(getCurrentPeriod()),
                  pyStartYear: Number(getPerformanceYear(getCurrentPeriod())) - 1,
                })}
                <span></span>
                <span></span>
              </Trans>
            </p>
          </section>
          <section>
            <h2 className="AnnualInfoDrawer__title">
              <Trans>{t("pqSubTitle", defaultContent["pqSubTitle"])}</Trans>
            </h2>
            <p className="AnnualInfoDrawer__desc">
              <Trans>{t("pqContent", defaultContent["pqContent"])}</Trans>
            </p>
          </section>
          <section>
            <h2 className="AnnualInfoDrawer__title">
              <Trans>{t("baselineSubTitle", defaultContent["baselineSubTitle"])}</Trans>
            </h2>
            <p className="AnnualInfoDrawer__desc">
              <Trans>{t("baselineContent", defaultContent["baselineContent"])}</Trans>
            </p>
          </section>
          <section>
            <h2 className="AnnualInfoDrawer__title">
              <Trans>{t("fqSubTitle", defaultContent["fqSubTitle"])}</Trans>
            </h2>
            <p className="AnnualInfoDrawer__desc">
              <Trans>{t("fqContent", defaultContent["fqContent"])}</Trans>
            </p>
          </section>
          <section>
            <h2 className="MpdInfoDrawer__title">
              <Trans>{t("infoDrawerAnnualPpvTitle", defaultContent["infoDrawerAnnualPpvTitle"])}</Trans>
            </h2>
            <p className="MpdInfoDrawer__desc">
              <Trans>{t("infoDrawerAnnualPpvDesc", defaultContent["infoDrawerAnnualPpvDesc"])}</Trans>
            </p>
          </section>
        </div>
      </LeafPanel>
    </div>
  );
};
