import { useTranslation } from "react-i18next";
import { useState } from "react";
import { LeafModal } from "../../../common/leaf/LeafModal/LeafModal";
import ServerSideExportMenu from "../ServerSideExportCommon/ServerSideExportMenu/ServerSideExportMenu";
import "./LOSExportModal.scss";
import defaultContent from "../../../content/serverSideExport";
import ServerSideExportDownload from "../ServerSideExportCommon/ServerSideExportDownload/ServerSideExportDownload";
import { storedLocale } from "../../impersonation/util";
import { useAppSelector } from "../../../store";
import { getCountryNameFromCode } from "../../../common/util/formatCountryName";
import { getLoginAffAbo } from "../../../reducers/model/boot";

type Props = {
  open: boolean;
  onCloseCallbackFn: Function;
};

export const LOSExportModal = (props: Props) => {
  const { t } = useTranslation(["serverSideExport"]);

  const [showServerSideExportMenu, setShowServerSideExportMenu] = useState(true);
  const locale = storedLocale();
  const boot = useAppSelector(({ boot }) => boot);
  const affAbo = getLoginAffAbo({ boot });
  const { isoCountryCode = "" } = boot?.user || {};
  const countryName = getCountryNameFromCode(isoCountryCode, locale);

  const { open, onCloseCallbackFn } = props;

  const options = [
    {
      id: "1",
      text: `${t("exportTypeModal.menu.los.all", defaultContent["exportTypeModal"]["menu"]["los"]["all"])}`,
    },
    {
      id: "2",
      text: `${t(
        "exportTypeModal.menu.los.selectedKPIs",
        defaultContent["exportTypeModal"]["menu"]["los"]["selectedKPIs"],
      )}`,
    },
    {
      id: "3",
      text: `${t(
        "exportTypeModal.menu.los.currentView",
        defaultContent["exportTypeModal"]["menu"]["los"]["currentView"],
      )}`,
    },
  ];

  const onDownloadButtonClick = (exportId: string, downloadType: string) => {
    // Place holder for implementation
  };

  const onExportAnotherClick = () => {
    setShowServerSideExportMenu(true);
  };

  const viewExportedDataButtonOnClick = () => {
    setShowServerSideExportMenu(true);
  };

  const viewAllExportedDataButtonOnClick = () => {
    setShowServerSideExportMenu(false);
  };

  const onCloseButtonClick = () => {
    setShowServerSideExportMenu(true);
    onCloseCallbackFn();
  };

  return (
    <div className="los-export-modal">
      <LeafModal open={open} onCloseCallbackFn={onCloseButtonClick}>
        <ServerSideExportMenu
          title={`${t("exportTypeModal.header", defaultContent["exportTypeModal"]["header"])}`}
          subTitle={`${t("exportTypeModal.subheader", defaultContent["exportTypeModal"]["subheader"])}`}
          options={options}
          exportButton={{
            text: `${t("exportTypeModal.exportButton", defaultContent["exportTypeModal"]["exportButton"])}`,
            onClick: viewExportedDataButtonOnClick,
          }}
          show={showServerSideExportMenu}
          showExportButton={true}
          showViewExportsButton={true} // to be updated based on data availability
          viewExportsButton={{
            text: `${t("exportTypeModal.viewExportButton", defaultContent["exportTypeModal"]["viewExportButton"])}`,
            onClick: viewAllExportedDataButtonOnClick,
          }}
        />
        <ServerSideExportDownload
          title={`${t("availableExportModal.header", defaultContent["availableExportModal"]["header"])}`}
          subTitle={`${t("availableExportModal.subheader", defaultContent["availableExportModal"]["subheader"])}`}
          exportTypeContent={{
            fullMap: `${t(
              "exportTypeModal.menu.los.selectedKPIs",
              defaultContent["exportTypeModal"]["menu"]["los"]["selectedKPIs"],
            )}`,
            fullMapAllKPIs: `${t(
              "exportTypeModal.menu.los.all",
              defaultContent["exportTypeModal"]["menu"]["los"]["all"],
            )}`,
            currentView: `${t(
              "exportTypeModal.menu.los.currentView",
              defaultContent["exportTypeModal"]["menu"]["los"]["currentView"],
            )}`,
          }}
          exports={[
            {
              exportId: "ILeHUEqfh",
              exportStatus: "DONE",
              pdfLoading: false,
              excelLoading: false,
              csvLoading: false,
              losCount: 465,
              enrichedCount: 465,
              progressPercent: 100,
              period: "202411",
              expiresInSeconds: 7656,
              startTime: 1738563561,
              noDownline: false,
              reqAffAbo: "430-143733",
              exportType: "FULL_MAP",
              filteredByKPIS: false,
              countryCode: "IN",
            },
            {
              exportId: "JLeHUEqfh",
              exportStatus: "ERROR",
              pdfLoading: false,
              excelLoading: false,
              csvLoading: false,
              losCount: 465,
              enrichedCount: 465,
              progressPercent: 100,
              period: "202411",
              expiresInSeconds: 172704,
              startTime: 1738563561,
              noDownline: false,
              reqAffAbo: "430-143733",
              exportType: "FULL_MAP",
              filteredByKPIS: true,
              countryCode: "IN",
            },
          ]}
          downloadButtonOnClick={onDownloadButtonClick}
          show={showServerSideExportMenu === false}
          showExportAnotherButton={true}
          exportAnotherButton={{
            text: `${t(
              "availableExportModal.exportAnotherButton",
              defaultContent["availableExportModal"]["exportAnotherButton"],
            )}`,
            onClick: onExportAnotherClick,
          }}
        />
      </LeafModal>
    </div>
  );
};
